<template>
  <section class="overlay-print text-center">
    <Errors :errors="errors" />
    <div class="content white">
      <h4>{{ $t("packetReturnShipment.printing") }}</h4>
      <div class="flex-group">
        <div class="item" v-if="depotLabelVisible">
          <h1>{{ $t("packetReturnShipment.label") }}</h1>
          <img
            src="../../public/depot-label.jpg"
            :alt="$t('packetReturnShipment.label')"
          />
          <p>{{ $t("packetReturnShipment.stick") }}</p>
        </div>
        <div class="item" v-if="consignmentReceiptVisible">
          <h1>{{ $t("packetReturnShipment.confirmation") }}</h1>
          <img
            src="../../public/confirmation-receipt.jpg"
            :alt="$t('packetReturnShipment.confirmation')"
          />
          <p>{{ $t("packetReturnShipment.hand-over") }}</p>
        </div>
      </div>
    </div>
    <div class="buttons container">
      <a
        href="#"
        v-if="depotLabelVisible"
        class="btn btn-primary btn-white"
        @click.prevent="reprintDepotLabel"
        >{{ $t("packetReturnShipment.print-label") }}</a
      >
      <a
        href="#"
        v-if="consignmentReceiptVisible"
        class="btn btn-primary btn-white"
        @click.prevent="reprintConsingnmentReceipt"
        >{{ $t("packetReturnShipment.print-confirmation") }}</a
      >
      <a href="#" class="btn btn-primary btn-green" @click.prevent="finish">{{
        $t("packetC2Creceive.finish")
      }}</a>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PRINT_TYPES from "@/store/print.type";
import Errors from "@/components/Errors";
import { getParams } from "@/mixins/platform-params.js";

export default {
  name: "OverlayPrint",
  components: { Errors },
  mixins: [getParams],
  computed: {
    ...mapState(["errors"]),
    ...mapState("packet", ["returnShipmentPrints"]),

    depotLabelVisible: {
      get: function() {
        return this.getPrint(PRINT_TYPES.DEPOT_LABEL) !== undefined;
      }
    },
    consignmentReceiptVisible: {
      get: function() {
        return this.getPrint(PRINT_TYPES.CONSIGNMENT_RECEIPT) !== undefined;
      }
    }
  },
  methods: {
    ...mapActions("packet", ["reprintLabel"]),

    async reprintDepotLabel() {
      await this.reprintLabel(
        this.getReprintIdBasedOnType(PRINT_TYPES.DEPOT_LABEL)
      );
    },

    async reprintConsingnmentReceipt() {
      await this.reprintLabel(
        this.getReprintIdBasedOnType(PRINT_TYPES.CONSIGNMENT_RECEIPT)
      );
    },

    getPrint(type) {
      return this.returnShipmentPrints.find(print => print.type == type);
    },

    getReprintIdBasedOnType(type) {
      let reprintId;
      const print = this.getPrint(type);

      if (print) {
        reprintId = print.reprintId;
      }

      return reprintId;
    },

    finish() {
      this.$router.replace({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-print {
  @include overlay;
  background: rgba(0, 0, 0, 0.95);

  .content {
    h4 {
      margin: 32px 0 20px;
    }

    .flex-group {
      display: flex;
      justify-content: center;

      img {
        height: 121px;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        h1 {
          font-size: 1.375rem;
          text-transform: uppercase;
          margin-bottom: 13px;
        }

        p {
          margin-top: 5px;
          max-width: 130px;
        }
      }
    }
  }

  .buttons {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 16px;

    a {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
